<script lang="ts" setup>import { ref as _ref, toRef as _toRef } from 'vue';

import { maska as vMask } from 'maska'
import { useFieldWithErrors } from '@consumer/composables/fieldWithErrors'
import { uniqueId } from '@consumer/helpers/string'
import { Dropdown, type Placement } from 'floating-vue'
import { useValidation } from '@consumer/composables/validation'

defineOptions({
  inheritAttrs: false,
})

withDefaults(defineProps<{
  type?: string
  id?: string
  modelValue?: string | number | null
  value?: any | null
  multiline?: boolean
  autoresize?: boolean // Only applicable with multiline: true
  label?: string
  hint?: string
  placeholder?: string
  placeholderAlignment?: 'left' | 'center' | 'right'
  icon?: string
  iconRight?: string
  hasError?: boolean
  error?: any
  errorTooltip?: boolean | Boolean | Placement | undefined
  mask?: string
  wrapperClass?: any
  validations?: any
}>(), { type: 'text',multiline: false,autoresize: false,placeholderAlignment: 'left',hasError: false,errorTooltip: false, })

const validator = useValidation(
  { value: __props.validations ?? {} },
  { value: computed(() => __props.modelValue ?? __props.value) } as any,
)

const inputId = computed(() => __props.id ?? uniqueId('g-input-'))

const emit = defineEmits<{
  'update:modelValue': [value: string]
  'update:activated': [value: boolean]
  focus: [value: FocusEvent]
  blur: [value: FocusEvent]
  input: [value: InputEvent]
  keydown: [value: KeyboardEvent]
  keyup: [value: KeyboardEvent]
}>()

const inputElement = ref<HTMLInputElement | HTMLTextAreaElement>()
const shadowTextarea = _ref<HTMLTextAreaElement>()

const __$temp_1 = (useFieldWithErrors(
  inputElement,
  () => __props.error ?? validator.value.$errors,
  () => __props.errorTooltip,
  () => __props.hasError,
)),
  activate = _toRef(__$temp_1, 'activate'),
  showError = _toRef(__$temp_1, 'showError'),
  errorMessages = _toRef(__$temp_1, 'errorMessages'),
  errorTooltipPlacement = _toRef(__$temp_1, 'errorTooltipPlacement');

const slots = useSlots()

const hasAddonLeft = computed(() => Boolean(slots.addonLeft || __props.icon))

const hasAddonRight = computed(() => Boolean(
  slots.addonRight || __props.iconRight || (showError.value && !__props.multiline),
))

const onInput = (event: InputEvent) => {
  const inputEl = event.target as HTMLInputElement | HTMLTextAreaElement
  emit('update:modelValue', inputEl?.value)
  emit('input', event)
}

const onKeypress = (event: KeyboardEvent) => {
  activate.value()

  if (event.key === 'Enter' && !__props.multiline) {
    (event.target as HTMLInputElement).blur()
  }
}

let inputHeight = _ref<string>()

function autoresizeTextarea () {
  if (__props.multiline && __props.autoresize && __props.modelValue && shadowTextarea.value) {
    shadowTextarea.value.value = String(__props.modelValue)
    nextTick(() => { inputHeight.value = `${Math.max(80, shadowTextarea.value?.scrollHeight || 0)}px` })
  }
}

watchEffect(autoresizeTextarea)

defineExpose({
  inputElement,
  activate: activate.value,
  focus: () => inputElement.value?.focus(),
  blur: () => inputElement.value?.blur(),
})
</script>

<template>
  <fieldset class="group g-input-field relative" :class="wrapperClass">
    <div v-if="label" class="flex flex-row items-center gap-1.5 mb-2.5 ">
      <slot name="label">
        <GLabel :for="inputId">
          <span v-html="label"/>
        </GLabel>
      </slot>

      <span class="font-normal text-sm text-grey-500">
        <slot name="hint">
          <span v-html="hint"/>
        </slot>
      </span>
    </div>
    <div :class="['g-input-group', 'relative', '-space-y-px', { 'has-error': showError || hasError }]">

      <!-- Optional left icon, either provide icon name as :icon prop or customize template #icon -->
      <div
        v-if="hasAddonLeft"
        class="
          absolute inset-y-0 flex items-center pointer-events-none
          left-0 pl-4 g-input-icon g-input-icon-left
        "
      >
        <slot name="addonLeft">
          <GIcon v-if="icon" :name="icon" color="grey-500"/>
        </slot>
      </div>

      <!-- Input can be customized throught template #input -->
      <slot v-bind="{ type, modelValue, placeholder, mask, hasAddonLeft, hasAddonRight, inputId }">
        <component
          :is="multiline ? 'textarea' : 'input'"
          :id="inputId"
          ref="inputElement"
          v-mask="mask"
          v-bind="$attrs"
          :class="[
            'w-full block',
            'outline-none border-solid border-1px border-grey-400 rounded-md',
            'g-input',
            hasAddonLeft && '!pl-10',
            hasAddonRight && '!pr-10',
            { multiline, autoresize },
          ]"
          :type="type"
          :value="modelValue || value"
          :placeholder="placeholder"
          @input="onInput"
          @keypress="onKeypress"
          @keydown="emit('keydown', $event)"
          @keyup="emit('keyup', $event)"
          @focus="emit('focus', $event)"
          @blur="emit('blur', $event)"
        />
        <textarea
          v-if="multiline && autoresize"
          ref="shadowTextarea"
          class="absolute w-full opacity-0 margin-0 max-h-0 pointer-events-none"
          tabindex="0"
          @keypress="onKeypress"
        />
      </slot>

      <Dropdown
        v-if="errorTooltip && showError"
        shown
        :triggers="[]"
        :autoHide="false"
        :flip="false"
        :placement="errorTooltipPlacement"
        popperClass="g-input-field-error-tooltip"
        :arrowPadding="8"
        :distance="8"
      >
        <template #popper>
          <ul class="p-4 px-6 text-red-base">
            <li v-for="errorMessage, i in errorMessages" :key="i">
              {{ errorMessage }}
            </li>
          </ul>
        </template>
      </Dropdown>

      <!-- Optional right icon, either provide icon name as :icon prop or customize template #iconRight-->
      <div
        v-if="hasAddonRight"
        class="
          absolute inset-y-0 flex items-center
          right-0 pr-4 g-input-icon g-input-icon-right
          text-grey-700
        "
      >
        <slot name="addonRight">
          <GIcon v-if="iconRight" :name="iconRight" color="grey-500"/>
          <GIcon v-else name="warning" color="red-base"/>
        </slot>
      </div>
    </div>

    <slot v-if="showError && !errorTooltip" name="errorMessage">
      <GInputError :error="errorMessages"/>
    </slot>
  </fieldset>
</template>

<style lang="scss">
.g-input-field {
  input:not([type="range"]),
  textarea {
    @apply font-sans text-base antialiased;
    @apply block flex-1 p-19px;
    @apply outline-none border-solid border-1px border-grey-400 rounded-md;
    @apply text-navy placeholder-grey-500;

    &:focus {
      @apply border-primary-base z-10;
    }

    &::placeholder {
      @apply text-grey-500;
    }
  }

  input {
    @apply h-55px;

    &::placeholder {
      text-align: v-bind("placeholderAlignment");
    }
  }

  textarea {
    overflow: hidden;
    resize: none;

    &.autoresize {
      height: v-bind("inputHeight");
    }
  }

  .has-error {
    input,
    textarea {
      @apply border-red-base text-red-base;

      &::placeholder {
        text-align: v-bind("placeholderAlignment");
      }
    }
  }
}

.g-input-field-error-tooltip {
  .v-popper__inner {
    @apply max-w-md sm:mx-6 lg:mx-0;
  }
}
</style>
